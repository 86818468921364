import axios from "axios"
import { json } from "stream/consumers"

class AuthApi {
    static register(register:any,action:(token:string) =>void,catc:(cash:any) =>void) {   
        axios.post('https://www.sell-guru.com/EXtensionAPI/api/Identity/SignUp',JSON.stringify(register),{
            headers:{
                'Access-Control-Allow-Origin': '*', 
	            'Content-Type': 'application/json'
            }
        }).then(response => {
            if(response.data.isSuccess){
                localStorage.setItem('sellguruTok',JSON.stringify(response.data.data))
                action(response.data.data.token)
            }            
        }).catch(cash =>{
            catc(cash)
            // console.log(cash)
        })
    }

    static login (login:any,action:(arr:any) =>void,catchs:(arr:any) => void) {
        axios.post('https://www.sell-guru.com/EXtensionAPI/api/Identity/SignIn',JSON.stringify(login),{
            headers:{
                'Access-Control-Allow-Origin': '*', 
	            'Content-Type': 'application/json'
            }
        }).then(response =>{
            if(response.data.isSuccess){
                localStorage.setItem('sellguruTok',JSON.stringify(response.data.data))
                window.location.href ='https://www.amazon.com/s?k=puzzle'
            }else{
                action(response.data.message)
            }          
        }).catch((res) => {
            catchs(res)
        })
    }

    static porsesh(fiels:number,token:string) {
        axios.post('https://www.sell-guru.com/EXtensionAPI/api/Identity/SetSellerType',fiels,{
            headers:{
                'Authorization':'Bearer ' +token
            }
        }).then(() => {
             window.location.href ='https://www.amazon.com/s?k=puzzle'
        })
    }

    static checkExtensionId(){
        // axios.post('https://www.sell-guru.com/EXtensionAPI/api/Identity/SignInWithKey',null,{
        //     headers: { 
        //         'ExtensionId':''+localStorage.getItem('chromeId')
        //         // 'Authorization':'Bearer ' + JSON.parse(localStorage.getItem('sellguruTok'))?.token  
        //     }
        // }).then(response => {
        //     if(response.data.isSuccess){
        //         localStorage.setItem('sellguruTok',JSON.stringify(response.data.data))
        //         window.location.href ='https://www.amazon.com/s?k=pazzle'
        //     }
        //     console.log(response)
        // })
    }

    static siginWithGoogle(googleData:any){
        console.log(googleData)
        // let data = {
        //     "ca": googleData.Ca,
        //     "cc":googleData.Bc,
        //     "gv": {
        //         "ox":googleData.lv.EX,
        //         "zf": googleData.lv.Af  ,
        //         "gZ":  googleData.lv.rZ ,
        //         "tX": googleData.lv.ZX,
        //         "gO": googleData.lv.nO,
        //         "tv":googleData.lv.Xv
        //         },
        //     "googleId": googleData.googleId,
        //     "tokenObj": googleData.tokenObj,
        //     "tokenId": googleData.tokenId,
        //     "accessToken": googleData.accessToken,
        //     "profileObj": googleData.profileObj
        //     }
        // console.log(localStorage.getItem('chromeId'))
        let extensionId = localStorage.getItem('chromeId')
        // let pars = localStorage.getItem('sellguruTok')
        // if(pars!=null){
        //     extensionId =JSON.parse(pars).token
        // }
        // let data ={
        //     "tokenId": googleData.tokenId,
        //     "profileObj":googleData.profileObj,
        //     "extensionId":extensionId
        //     // "extensionId"
            
        //     }
       
        let data ={
            "profileObj":googleData.profileObj,
            "extensionId":extensionId
        }     

        axios.post('https://www.sell-guru.com/EXtensionAPI/api/Identity/SignInWithGoogle',data).then(response => {
            // console.log(response)
            if(response.data.isSuccess){
                window.location.href ='https://www.amazon.com/s?k=puzzle'
            }
        })
    }
}

export default AuthApi