import React from "react"

const CheckBox = (props:any) => {
  return (
    <>
    <div >
      {props.checked ? 
        <img style={{minWidth:'16px',minHeight:'16px',cursor:'pointer',width:'20px',height:'20px'}} src="checkBox-checked.svg"  onClick={() =>props.setchecked()} alt="" />
      :
        <img style={{minWidth:'16px',minHeight:'16px',cursor:'pointer',width:'20px',height:'20px'}} src="checkBox-unchecked.svg" onClick={() =>props.setchecked()} alt="" />
      }
    </div>
    </>
  )
}

export default CheckBox