import React from "react"

const QualitiveAnalize = () => {
    return (
        <>
            <div style={{position:'relative',marginTop:'42px'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <div className="responsiveImageHeaderLayout" style={{alignItems:'center',position:'relative',zIndex:10}}>
                        <img className="responsiveOrderGridLayout" style={{position:'relative',zIndex:100,width:window.innerWidth>1024? '70%':'100%',marginTop:'16px'}} src="./imgs/laptopBack.png" alt="" />
                        <div style={{textAlign:'left',display:'flex',justifyContent:'center'}}>
                            <div>
                                <div className="headerGreen">Qualitative Analysis</div>
                                <p className="pargraphcontent">Decide Based on Analyzed Data</p>
                                <button onClick={() => window.open('https://chrome.google.com/webstore/detail/sellguru-free-amazon-nich/kpbjfajjiamcmkngfffakflnfkincbmb')} className="ButtonBlue" style={{marginTop:'30px'}}><span>Start to Find</span> <img src="./icons/fi_arrow-right.svg" style={{marginLeft:'8px'}} alt="" /></button>
                            </div>
                        </div>
                    </div>
                </div>
                <img style={{width:'100%',position:'absolute',top:'0px',left:'0px'}} src="./imgs/back1.png" alt="" />
            </div>
        </>
    )
}

export default QualitiveAnalize
