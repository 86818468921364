import React from "react"

const LookingForProducts = () => {
 return (
        <>
            <div style={{position:'relative',marginTop:'150px'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <div className="responsiveImageHeaderLayout" style={{alignItems:'center',justifyContent:'space-evenly',width:'100%',position:'relative',zIndex:10}}>
                        <img className="responsiveOrderGridLayout" style={{position:'relative',width:window.innerWidth>1024? '45%':'100%',zIndex:100}} src="./imgs/lookProducts.png" alt="" />
                        <div style={{textAlign:'left',display:'flex',justifyContent:'center'}}>
                            <div style={{display:window.innerWidth>600 ?'flex':'block'}}>
                                <img style={{marginRight:'0px',height:'153px'}} src="./imgs/2.gif" alt="" />
                                <div>
                                    <div className="headerGreen">Looking for Products</div>
                                    <p className="pargraphcontent" style={{width:'304px'}}>Search your ideas on Amazon to activate SellGuru Extension</p>
                                    <div style={{display:'flex',justifyContent:'end'}}>
                                        <button onClick={() => window.open('https://chrome.google.com/webstore/detail/sellguru-free-amazon-nich/kpbjfajjiamcmkngfffakflnfkincbmb')} className="ButtonBlue" style={{marginTop:'30px',padding:'9px 30px'}}><span>Try it</span> </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img style={{width:'100%',position:'absolute',top:'210px',left:'0px'}} src="./imgs/back1.png" alt="" />
            </div>
        </>
    )
}

export default LookingForProducts