import MasterFooter from "../components/MasterFooter"
import MasterHeader from "../components/MasterHeader"
import PlansPanel from "../components/PlansPanel"

const Payment = () => {
    return (
        <>
            <MasterHeader></MasterHeader>
            <PlansPanel></PlansPanel>

            <div style={{display:'flex',padding:'0px 100px',marginTop:'54px',marginBottom:'26px',alignItems:'center',justifyContent:'space-evenly'}}>
                <div>
                    <img src="./icons/fi_sliders.svg" alt="" />
                    <div style={{color:'#253343',marginTop:'20px',fontSize:'20px',fontWeight:400}}>Comparing Niches</div>
                </div>

                <div>
                    <img src="./icons/fi_coffee.svg" alt="" />
                    <div style={{color:'#253343',marginTop:'20px',fontSize:'20px',fontWeight:400}}>Using Mentorship</div>
                </div>

                <div>
                    <img src="./icons/fi_layers.svg" alt="" />
                    <div style={{color:'#253343',marginTop:'20px',fontSize:'20px',fontWeight:400}}>Using Smart Reports</div>
                </div>                                
            </div>

            <MasterFooter></MasterFooter>
        </>
    )
}

export default Payment