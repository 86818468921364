const Message = (props: any) => {
    document.getElementById('Messages4')?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
    return (
        <>
            <div onClick={() =>  undefined} id={"Messages"+props.index} style={{width:'722px',margin:'0px 45px',padding:'40px 55px',paddingBottom:'37px',backgroundColor:'white',borderRadius:'20px',border:'1px solid #AEC1DA',boxShadow:'0px 0px 16px rgba(0, 0, 0, 0.12)'}}>
                <p style={{textAlign:'left',fontSize:'18px',width:'604px',fontWeight:400,color:'#445A74',lineHeight:'32px'}}>{props.item.content}</p>

                <div style={{display:'flex',justifyContent:'end'}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div>
                            <div style={{color:'#445A74',fontSize:'16px',fontWeight:500}}>smauel jack</div>
                            <div style={{color:'#7B93AF',fontSize:'16px',marginTop:'8px',fontWeight:300}}>Amazon Seller</div>
                        </div>

                        <img style={{width:'73px',height:'73px',borderRadius:'100%',border:'1px solid #FFFFFF',filter:'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.23))',marginLeft:'20px'}} src="./imgs/avatar.jpg" alt="" />
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Message