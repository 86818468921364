const Compare = () => {
 return (
        <>
            <div style={{position:'relative',marginTop:'100px'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <div className="responsiveImageHeaderLayout" style={{alignItems:'center',justifyContent:'space-evenly',width:'100%',position:'relative',zIndex:10}}>
                        <img className="responsiveOrderGridLayout" style={{position:'relative',width:window.innerWidth>1024? '40%':'100%',zIndex:100}} src="./imgs/compare.png" alt="" />
                        <div style={{textAlign:'left',display:'flex',justifyContent:'center'}}>
                            <div style={{display:window.innerWidth>600 ?'flex':'block',width:window.innerWidth>600 ?'unset':'300px',padding:'8px'}}>
                                <div style={{backgroundColor:'#F3F3F3'}}>
                                    <img style={{marginTop:'25px',height:'168px',mixBlendMode:'multiply'}} src="./imgs/5.gif" alt="" />

                                </div>
                                <div>
                                    <div className="headerGreen" style={{width:'300px'}}>Compare and Choose the Best  </div>
                                    <p className="pargraphcontent" style={{width:'95%'}}>Compare and Contrast All Saved Niches based on Qualitative Results</p>
                                    <div style={{display:'flex',justifyContent:'end',width:'90%'}}>
                                        <button onClick={() => window.open('https://chrome.google.com/webstore/detail/sellguru-free-amazon-nich/kpbjfajjiamcmkngfffakflnfkincbmb')}  className="ButtonBlue" style={{marginTop:'30px'}}><span>Download</span> <img src="./icons/download.svg" style={{marginLeft:'8px'}} alt="" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="compareBakImage" style={{width:'100%',height:'588px',position:'absolute',left:'0px'}} src="./imgs/backGray.png" alt="" />
            </div>
        </>
    )
}

export default Compare