const Archive = () => {
 return (
        <>
            <div style={{position:'relative',marginTop:'150px'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <div className="responsiveImageHeaderLayout" style={{alignItems:'center',justifyContent:'space-evenly',width:'100%',position:'relative',zIndex:10}}>
                        <div style={{textAlign:'left',display:'flex',justifyContent:'center'}}>
                            <div style={{display:window.innerWidth>700 ?'flex':'block'}}>
                                <img style={{marginRight:'0px',height:'191px'}} src="./imgs/3.gif" alt="" />
                                <div style={{width:window.innerWidth>600 ?'unset':'300px'}}>
                                    <div className="headerGreen">Achieve Predictive Results</div>
                                    <p className="pargraphcontent" style={{width:window.innerWidth>600 ?'480px':'300px'}}>Review SellGuru Qualitative Results to Predict Market Size and Potential of each Niche</p>
                                    <button onClick={() => window.open('https://chrome.google.com/webstore/detail/sellguru-free-amazon-nich/kpbjfajjiamcmkngfffakflnfkincbmb')} className="ButtonBlue" style={{marginTop:'30px'}}><span>Download</span> <img src="./icons/download.svg" style={{marginLeft:'8px'}} alt="" /></button>
                                </div>
                            </div>
                        </div>
                        <img className="responsiveOrderGridLayout" style={{position:'relative',width:window.innerWidth>1024? '45%':'100%',zIndex:100}} src="./imgs/Archive.png" alt="" />
                    </div>
                </div>
                {/* <img style={{width:'100%',position:'absolute',top:'210px',left:'0px'}} src="./imgs/back1.png" alt="" /> */}
            </div>
        </>
    )
}

export default Archive