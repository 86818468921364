const FooterBar = () => {
    return (
        <>
            <div style={{position:'relative',width:'100%',backgroundColor:'rgba(37, 51, 67, 0.9)'}}>
                <div className="footerPadding" style={{textAlign:'left'}}>
                    <img src="./imgs/logo.png" alt="" />
                    <div className="responsiveFlexable"  style={{justifyContent:'space-between',alignItems:'center',marginTop:'21px'}}>
                        <div style={{fontSize:'12px',fontWeight:'400',color:'rgba(245, 247, 250, 1)'}}>Platform for sales in Amazon</div>

                        <div className="footerMarginTop" style={{display:'flex',alignItems:'center'}}>
                            <div onClick={() => window.open('https://chrome.google.com/webstore/detail/sellguru-free-amazon-nich/kpbjfajjiamcmkngfffakflnfkincbmb')} style={{fontSize:'12px',display:'flex',cursor:'pointer',alignItems:'center',fontWeight:'400',color:'rgba(245, 247, 250, 1)'}}>Download Extension <img style={{marginLeft:'8px'}} src="./icons/download2.svg" alt="" /></div>
                            <div style={{fontSize:'12px',display:'flex',cursor:'pointer',alignItems:'center',fontWeight:'400',color:'rgba(245, 247, 250, 1)',marginLeft:'23px'}}>Share<img style={{marginLeft:'8px'}} src="./icons/share.svg" alt="" /></div>
                        </div>

                    </div>
                    
                    <div style={{width:'100%',height:'1px',backgroundColor:'rgba(255, 255, 255, 1)',opacity:'0.1',margin:'14px 0px'}}></div>

                    <div className="responsiveFlexable" style={{alignItems:'center',justifyContent:'space-between'}}>
                        <div style={{color:'rgba(255, 255, 255, 1)',opacity:'0.5',fontSize:'14px',fontWeight:400}}>Copyright © 2021. SellGuru. All rights reserved.</div>

                        <div className="footerMarginTop" style={{display:'flex'}}>
                            <div style={{color:'rgba(255, 255, 255, 1)',fontSize:'12px',fontWeight:400}}>Terms & Conditions</div>
                            <div style={{width:'1px',height:'13px',opacity:'0.1',margin:'0px 18px',backgroundColor:'rgba(255, 255, 255, 1)'}}></div>
                            <div style={{color:'rgba(255, 255, 255, 1)',fontSize:'12px',fontWeight:400}}>Privacy</div>
                        </div>
                    </div>
                </div>
                <img style={{width:'100%',position:'absolute',top:'0px',left:'0px',zIndex:-10,height:'-webkit-fill-available'}} src="./imgs/backFooter.png" alt="" />
            </div>
        </>
    )
}

export default FooterBar