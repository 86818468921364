import React from 'react' 
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loader =() => {
    return (
        <>
            <div style={{width:'100%',textAlign:'center',height:'100%',backgroundColor:'#F8F9FC',borderRadius:'8px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                {/* <div> */}
                        {/* Loading ....

                        <div style={{position:'relative',width:'182px',height:'8px',backgroundColor:'#D0DDEC',borderRadius:'25px',marginTop:'8px'}}>
                        <div style={{position:'absolute',top:'0px',width:'100px',height:'8px',backgroundColor:'#57728E',borderRadius:'25px',left:'0px'}}></div>
                        </div> */}
                <Box sx={{ display: 'flex' }}>
                <CircularProgress />
                </Box>                        
                {/* </div> */}
            </div>
        </>
    )
}

export default Loader