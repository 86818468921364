import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import GoogleLogin from "react-google-login";
import CheckBox from "../components/CheckBox";
import AuthApi from "../components/AuthApi";
import Loader from "../components/Loader";

// import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const AuthPage2 = () => {
  useEffect(() => {
    AuthApi.checkExtensionId()
  })

  // console.log(localStorage.getItem('sellguruTok'))
   const { register, handleSubmit, formState: { errors } } = useForm();
   const [isRobat,setisRobat] = useState('')
   let [registerdata,setregister] = useState({
        name:'',
        email:'',
        password:'',
    })   
    let [rememberMe,setRememberMe] = useState(false)

    let [token,setToken] = useState('')
    const onSubmit = (data:any) => console.log(data);
    let [activePanel,setActivePanel] = useState('Sign Up')
    let [afterLogin,setAfterLogin] = useState(false)
    let [selected,setSelected] = useState(0)
    let [showLoader,setShowLoader] = useState(false)
    const registerUser = () => {
        handleSubmit(onSubmit)
        if(!errors.nameField && !errors.passField &&!errors.emailField){
          setShowLoader(true)
            // api.post(VAR_HOST+'/register',registerdata).then(response => {
            //     console.log(response)
            //     if(response.data.name) {
            //         localStorage.setItem('authenticate',JSON.stringify(new User(response.data.id,response.data.name,response.data.email,response.data.image)))
            //         globalContext.setUser(new User(response.data.id,response.data.name,response.data.email,response.data.image))
            //         globalContext.setIsLogin(true)  
            //     }
            // })

            AuthApi.register({
                email:registerdata.email,
                firstname:registerdata.name,
                lastname:registerdata.name,
                password:registerdata.password,
                extensionId:localStorage.getItem('chromeId')
            },(token:string) => {
              setAfterLogin(true)
              setShowLoader(false)
              setToken(token)
              window.location.href ='https://www.amazon.com/s?k=puzzle'
            },
            (ca:any) => {
              setShowLoader(false)
              alert(ca)
            }
            )
        }
    }     
    
    const login =() => {
        handleSubmit(onSubmit)
        // if(registerdata.email=='AMIRADMIN'&&registerdata.password=='ADMIN'){
        //         localStorage.setItem('authenticate',JSON.stringify(new User(1,'ADMIN','ADMIN@ADMIN','')))
        //         globalContext.setUser(new User(1,'ADMIN','ADMIN@ADMIN',''))
        //         globalContext.setIsLogin(true)              
        // }
         if(!errors.nameField && !errors.passField &&!errors.emailField&&isRobat.length>=10){
              setShowLoader(true)
                // api.post(VAR_HOST+'/login',{
                //     Email:registerdata.email,
                //     password:registerdata.password
                // }).then(response => {
                //     if(response.data.name) {
                //         localStorage.setItem('authenticate',JSON.stringify(new User(response.data.id,response.data.name,response.data.email,response.data.image)))
                //         globalContext.setUser(new User(response.data.id,response.data.name,response.data.email,response.data.image))
                //         globalContext.setIsLogin(true)  
                //     }else{
                //         alert(response.data)
                //     }
                // })    
                
                AuthApi.login({
                    username:registerdata.email,
                    password:registerdata.password,
                    extensionId:localStorage.getItem('chromeId')
                },(res:any) =>{
                  alert(res)
                  setShowLoader(false)
                },(res:any) => {
                  // console.log(res)
                  alert('userName or password is incorrect')
                  setShowLoader(false)
                }
                )

         }
    }    
    const responseGoogle = (response:any) => {
      AuthApi.siginWithGoogle(response)
      // window.location.href = 'https://www.amazon.com/'
      console.log(response);
    }

    const faildResponse = (response:any) => {
      console.log(response)
    }
    const showValidationName = () => {
        return errors.nameField &&registerdata.name.length>0        
    }

    const showValidationPassword = () => {
        return errors.passField && registerdata.password.length>0        
    }    

    const showValidationEmail = () => {
        return errors.emailField && registerdata.email.length>0        
    }     

    const activeButton = () => {
        return showValidationName() || showValidationEmail() || showValidationPassword() || isRobat.length<10
    }
    return (
        <>   
            <div onMouseEnter={handleSubmit(onSubmit)} style={{backgroundColor:'#F8F9FC',height:'100vh' ,position:'relative'}}>
                <div style={{backgroundColor:'white',padding:'0px 60px',height:'63px',boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.1)',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <img alt='' src='logo.png' style={{height:'34px'}} />
                    <div style={{color:'#7B93AF',fontSize:'16px',fontWeight:400}}>support@Sell-guru.com</div>
                </div>       

                <div style={{width:'100%',height:'90%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <div style={{width:'900px',position:'relative',height:'591px',borderRadius:'12px',background:'white',boxShadow:'0px 2.96269px 6.66606px rgba(0, 0, 0, 0.3)'}}>

                        <div style={{textAlign:'left',position:'relative',zIndex:50,paddingTop:'40px',paddingLeft:'56px'}}>
                            <div>
                                <div style={{color:'#253343',fontSize:'28px',fontWeight:500}}>
                                    {activePanel}
                                </div>          
                                <div style={{fontSize:'14px',marginBottom:'8px',color:'#57728E',fontWeight:400,marginTop:'8px'}}>Join SellGuru to increse your sell on Amazon</div>                                                      
                            </div>
                        
                            {
                                activePanel ==='Sign Up'?
                                <>
                                    <div>
                                        <div style={{color:'#445A74',fontSize:'14px',marginLeft:'8px'}}>Name</div>
                                        <div>
                                            <input id="sellguru-NameInput"  onInput={(event:any) =>setregister({...registerdata,name:event.target.value}) } placeholder="First and last name" {...register("nameField",{ required:'This field is required', maxLength: 30,minLength:3})}   type="text"  style={{width:'307px',height:'40px',color:showValidationName()?'#F52C2C':'unset',border:showValidationName()?'1px solid #F52C2C':'1px solid #AEC1DA',backgroundColor:'white',paddingLeft:'11px',borderRadius:'5px',marginTop:'8px'}} className="InutFieldClass" />
                                            <div style={{textAlign:'left'}}>
                                                <div style={{fontSize:'12px',visibility:(showValidationName())?'visible':'hidden',marginTop:'2px',color:'#F52C2C',marginLeft:'1px'}}>Please enter full name</div>
                                                {/* {errors.nameField?.type==='minLength' && <div style={{fontSize:'12px',marginTop:'4px',color:'#F52C2C',marginLeft:'1px'}}>Please enter full name</div>} */}
                                                {/* {errors.nameField?.type==='maxLength' && <div style={{fontSize:'12px',marginTop:'4px',color:'#F52C2C',marginLeft:'1px'}}>Must be a maximum of 30 characters</div>} */}

                                            </div>

                                        </div>
                                    </div>      
                                </>
                                :undefined
                            }

                            <div style={{marginTop:'2px'}}>
                                <div  style={{color:'#445A74',fontSize:'14px',marginLeft:'8px'}}>Email</div>
                                <div>
                                    <input className="InutFieldClass" id="sellguru-EmailInput" onInput={(event:any) =>setregister({...registerdata,email:event.target.value}) } placeholder="This will be your Log In" {...register("emailField",{ required:'This field is required',pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g})}   type="text"  style={{width:'307px',height:'40px',color:showValidationEmail()?'#F52C2C':'unset',border:showValidationEmail()?'1px solid #F52C2C':'1px solid #AEC1DA',backgroundColor:'white',paddingLeft:'11px',borderRadius:'5px',marginTop:'8px'}} />
                                    <div style={{textAlign:'left'}}>
                                    {/* {errors.emailField && <div style={{fontSize:'12px',marginTop:'4px',color:'#F52C2C',marginLeft:'1px'}}>{errors.nameField?.message?.toString()}</div>} */}
                                     <div style={{fontSize:'12px',visibility:showValidationEmail()?'visible':'hidden',marginTop:'2px',color:'#F52C2C',marginLeft:'1px'}}>Please enter valid Email</div>
                                    </div>  

                                </div>
                            </div>      

                            <div style={{marginTop:'2px'}}>
                                <div  style={{color:'#445A74',fontSize:'14px',marginLeft:'8px'}}>Password</div>
                                <div>
                                    <input className="InutFieldClass" id="sellguru-passwordInput" onInput={(event:any) =>setregister({...registerdata,password:event.target.value}) } placeholder="Choose a strong password" {...register("passField",{ required:'This field is required',pattern:/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/})}   type="password"  style={{width:'307px',height:'40px',color:showValidationPassword()?'#F52C2C':'unset',border:showValidationPassword()?'1px solid #F52C2C':'1px solid #AEC1DA',backgroundColor:'white',paddingLeft:'11px',borderRadius:'5px',marginTop:'8px'}} />
                                    <div style={{textAlign:'left'}}>
                                    <div style={{fontSize:'12px',visibility:showValidationPassword()?'visible':'hidden',width:'340px',marginTop:'2px',color:'#F52C2C',marginLeft:'1px'}}>At least 8 characters and contain letters, numbers & symbols</div>
                                    </div>

                                </div>
                            </div>           
                            {activePanel ==='Log In' ? 
                            <>
                                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'322px',marginTop:'8px',marginBottom:'32px'}}>
                                    <div style={{display:'flex',alignItems:'center',justifyContent:'start'}}>
                                        <div style={{marginLeft:'4px'}}>
                                            <CheckBox checked={rememberMe} setchecked={() => setRememberMe(!rememberMe)}></CheckBox>
                                        </div>
                                        <div style={{fontSize:'12px',color:'#445A74',marginLeft:'8px'}}>Remember my login info</div>
                                    </div>

                                    <div style={{fontSize:'14px',color:'#253343',fontWeight:400,textDecorationLine:'underline'}}>Forget password</div>
                                </div>   
                            </>

                            :undefined
                        }
                            <div style={{marginTop:'0px',visibility:'visible',overflow:'hidden'}} className="g-recaptcha-inner">
                                <ReCAPTCHA
                                    sitekey="6Ldx470jAAAAAPmcR0PSCklEFBZXikR3tOGCxnAJ"
                                    onChange={(value:any) => {
                                        // console.log(value)
                                        setisRobat(value)
                                    }}
                                />                                                                                             
                                {/* <GoogleReCaptchaProvider reCaptchaKey="6LcaLrYjAAAAAA9Gku4Qu7ljZpOuBQBIQvsEaHna">
                                    <GoogleReCaptcha onVerify={() => {}} />
                                </GoogleReCaptchaProvider> */}

                              
                            </div>

                            <button onClick={activePanel ==='Log In' ? () => {

                                login()
                            } :() => {
                                registerUser()
                            }} style={{width:'322px',border:'none',backgroundColor:'#929DFE',borderRadius:'5px',color:'white',cursor:activeButton()?'not-allowed':'pointer',marginTop:'8px',height:'40px',display:'flex',alignItems:'center',justifyContent:'center'}}>{activePanel}</button>    

                            <GoogleLogin
                                clientId="849222309509-immkjkjh80p2720gah77tpvt6r06h7e9.apps.googleusercontent.com"
                                buttonText="Log In"
                                render={(renderProps:any) => (
                                <button onClick={renderProps.onClick} style={{width:'322px',marginBottom:'0px',border:'1px solid #AEC1DA',backgroundColor:'white',borderRadius:'5px',color:'#57728E',cursor:'pointer',marginTop:'12px',height:'40px',fontWeight:500,display:'flex',alignItems:'center',justifyContent:'center'}}> <img style={{marginRight:'8px'}} alt='' src='./imgs/google.png' /> {activePanel} with Google</button> 
                                )}
                                onSuccess={responseGoogle}
                                onFailure={faildResponse}
                                cookiePolicy={'single_host_origin'}
                                />      
                            <div style={{width:'322px'}}>
                                <div style={{fontSize:'12px',fontWeight:400,color:'#AEC1DA',marginTop:activePanel==='Sign Up'?"19px":'48px',textAlign:'center'}}>{activePanel==='Sign Up'?'Already have an account? ':'New to SellGuru? '} <span onClick={() => activePanel==='Log In'? setActivePanel('Sign Up') :setActivePanel('Log In')} style={{fontWeight:500,color:'#666EB2',cursor:'pointer'}}>{activePanel==='Sign Up'?'Log In':'Sign up'}</span></div>                                                   
                            </div>
                        </div>

                        <div style={{display:'flex',position:'absolute',width:'100%',height:'100%',alignItems:'center',top:'0px',zIndex:10,justifyContent:'end'}}>
                            {activePanel ==='Sign Up'?
                            <>
                                <img style={{}} src="./imgs/LoginBack.png" alt="" />
                            </>
                            :
                            <>
                                <img style={{}} src="./imgs/LoginBack2.png" alt="" />
                            </>    
                            }
                        </div>
                    </div>
                </div>  
                {
                    showLoader?
                    <>
                    <div style={{position:'absolute',width:'100%',height:'100%',left:'0px',top:'0px',opacity:'0.8',display:'flex',alignItems:'center',zIndex:5000,justifyContent:'center'}}>
                        <Loader></Loader>
                    </div>
                    </>
                    :
                    undefined
                }                
            </div>        
        </>
    )
}
export default AuthPage2