import React from "react"

const DownloadAndInstall = () => {
 return (
        <>
            <div style={{position:'relative',marginTop:'100px'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <div className="responsiveImageHeaderLayout" style={{alignItems:'center',justifyContent:'space-evenly',width:'100%',position:'relative',zIndex:10}}>
                        <div style={{textAlign:'left',display:'flex',justifyContent:'center'}}>
                            <div style={{display:window.innerWidth>600 ?'flex':'block'}}>
                                <img style={{marginRight:'0px',width:'187px',height:'182px'}} src="./imgs/1.gif" alt="" />
                                <div>
                                    <div className="headerGreen">Download & Install </div>
                                    <p className="pargraphcontent" style={{width:'304px'}}>Download Completely Free SellGuru Extension from Chrome Web Store</p>
                                    <button onClick={() => window.open('https://chrome.google.com/webstore/detail/sellguru-free-amazon-nich/kpbjfajjiamcmkngfffakflnfkincbmb')} className="ButtonBlue" style={{marginTop:'30px'}}><span>Download</span> <img src="./icons/download.svg" style={{marginLeft:'8px'}} alt="" /></button>
                                </div>
                            </div>
                        </div>
                        <img className="responsiveOrderGridLayout" style={{position:'relative',width:window.innerWidth>1024? '45%':'100%',zIndex:100}} src="./imgs/downloadLoptop.png" alt="" />
                    </div>
                </div>
                {/* <img style={{width:'100%',position:'absolute',top:'0px',left:'0px'}} src="./imgs/back1.png" alt="" /> */}
            </div>
        </>
    )
}

export default DownloadAndInstall