const HeaderBar = () => {
    return (
        <>
            <div style={{display:'flex',alignItems:'center',paddingTop:'32px',justifyContent:'space-between'}}>
                <div className="MainMargin">
                    <img src="./imgs/logo.png" alt="" />
                </div>

                <div className="marginRightRisponsive" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    {/* <img className="" style={{width:'24px'}}  src="./icons/search.svg" alt="" /> */}

                    {/* <div className="header-steps mobileInvisible">Contact Us</div> */}

                    <div onClick={() => window.open('https://chrome.google.com/webstore/detail/sellguru-free-amazon-nich/kpbjfajjiamcmkngfffakflnfkincbmb')} className="header-steps">Download</div>

                    {/* <button className="defaultButton">Sign up</button> */}
                </div>
            </div>
        </>
    )
}

export default HeaderBar