const MasterHeader = () => {
    return (
        <>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:'0px 100px',height:'63px',background:'white'}}>
                <img src="logo.png" alt="" />

                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <img style={{marginRight:'16px'}} src="./icons/search.svg" alt="" />
                    <img style={{marginRight:'16px'}} src="./icons/ring.svg" alt="" />
                    <img style={{marginRight:'16px'}} src="./icons/chat.svg" alt="" />
                    <img src="https://joeschmoe.io/api/v1/mail@ashallendesign.co.uk" style={{width:'35px',border:'1px solid #D0DDEC',height:'35px',filter:'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))',borderRadius:'100%',marginRight:'4px'}} alt="" />
                    <img src="./icons/fi_chevron-down.svg" alt="" />
                </div>
            </div>
        </>
    )
}

export default MasterHeader