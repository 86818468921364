/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react"
import ModalVideo from 'react-modal-video'

const SellingOnAmazon = () => {
    const [isOpen, setOpen] = useState(false)
    return (
        <>
            <div style={{position:'relative',marginTop:'100px'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <div className="responsiveImageHeaderLayout" style={{alignItems:'center',justifyContent:'space-evenly',width:'100%',position:'relative',zIndex:10}}>
                        <div style={{position:'relative'}}>
                            <img className="responsiveOrderGridLayout" style={{position:'relative',width:window.innerWidth>1024?'auto':'450px',zIndex:100}} src="./imgs/sellingLoptop.png" alt="" />
                            <div id="playerWrapper" style={{position:'absolute',top:window.innerWidth>1024?'40px':'20px',zIndex:500,left:window.innerWidth>1024?'30px':'10px'}}>
                                {window.innerWidth > 1024 ?
                                    <>
                                    <iframe id="player" width="560" height="320"
                                        src="https://www.youtube.com/embed/p-HX5ezPcsQ?enablejsapi=1"
                                        ></iframe>      
                                    </>
                                    :
                                    <>
                                    <iframe id="player" width="420" height="250"
                                        src="https://www.youtube.com/embed/p-HX5ezPcsQ?enablejsapi=1"
                                        ></iframe>                                          
                                    </>    
                                }
                            </div>
                        </div>
                        <div style={{textAlign:'left',display:'flex',justifyContent:'center'}}>
                            <div>
                                <h3 style={{width:'350px',fontSize:'50px',fontWeight:300,color:'#253343'}}>Selling on <span style={{fontWeight:900,color:'#FFB800'}}>AMAZON</span> is Esay JUST IF You have a Plan</h3>                              
                            </div>
                        </div>
                    </div>
                </div>
                <img style={{width:'100%',position:'absolute',top:'300px',left:'0px'}} src="./imgs/back1.png" alt="" />

            </div>  


            {/* <React.Fragment>
                <ModalVideo channel='youtube' isOpen={isOpen} videoId="L61p2uyiMSo" onClose={() => setOpen(false)} />

                <button className="btn-primary" onClick={()=> setOpen(true)}>VIEW DEMO</button>
            </React.Fragment>   */}
        </>
    )
}

export default SellingOnAmazon