import { useForm } from "react-hook-form";
import {useEffect, useState} from 'react'
import CheckBox from '../components/CheckBox';
import AuthApi from '../components/AuthApi';
import GoogleLogin from 'react-google-login';
import { Loader } from "../components";
function AuthPage() {
  useEffect(() => {
    AuthApi.checkExtensionId()
  })

  // console.log(localStorage.getItem('sellguruTok'))
   const { register, handleSubmit, formState: { errors } } = useForm();
   let [registerdata,setregister] = useState({
        name:'',
        email:'',
        password:'',
    })   
    let [rememberMe,setRememberMe] = useState(false)

    let [token,setToken] = useState('')
    const onSubmit = (data:any) => console.log(data);
    let [activePanel,setActivePanel] = useState('Signup')
    let [afterLogin,setAfterLogin] = useState(false)
    let [selected,setSelected] = useState(0)
    let [showLoader,setShowLoader] = useState(false)
    const registerUser = () => {
        handleSubmit(onSubmit)
        if(!errors.nameField && !errors.passField &&!errors.emailField){
          setShowLoader(true)
            // api.post(VAR_HOST+'/register',registerdata).then(response => {
            //     console.log(response)
            //     if(response.data.name) {
            //         localStorage.setItem('authenticate',JSON.stringify(new User(response.data.id,response.data.name,response.data.email,response.data.image)))
            //         globalContext.setUser(new User(response.data.id,response.data.name,response.data.email,response.data.image))
            //         globalContext.setIsLogin(true)  
            //     }
            // })

            AuthApi.register({
                email:registerdata.email,
                firstname:registerdata.name,
                lastname:registerdata.name,
                password:registerdata.password,
                extensionId:localStorage.getItem('chromeId')
            },(token:string) => {
              setAfterLogin(true)
              setShowLoader(false)
              setToken(token)
              // window.location.href ='https://www.amazon.com/'
            },
            (ca:any) => {
              setShowLoader(false)
              alert(ca)
            }
            )
        }
    }     
    
    const login =() => {
        handleSubmit(onSubmit)
        // if(registerdata.email=='AMIRADMIN'&&registerdata.password=='ADMIN'){
        //         localStorage.setItem('authenticate',JSON.stringify(new User(1,'ADMIN','ADMIN@ADMIN','')))
        //         globalContext.setUser(new User(1,'ADMIN','ADMIN@ADMIN',''))
        //         globalContext.setIsLogin(true)              
        // }
         if(!errors.nameField && !errors.passField &&!errors.emailField){
              setShowLoader(true)
                // api.post(VAR_HOST+'/login',{
                //     Email:registerdata.email,
                //     password:registerdata.password
                // }).then(response => {
                //     if(response.data.name) {
                //         localStorage.setItem('authenticate',JSON.stringify(new User(response.data.id,response.data.name,response.data.email,response.data.image)))
                //         globalContext.setUser(new User(response.data.id,response.data.name,response.data.email,response.data.image))
                //         globalContext.setIsLogin(true)  
                //     }else{
                //         alert(response.data)
                //     }
                // })    
                
                AuthApi.login({
                    username:registerdata.email,
                    password:registerdata.password,
                    extensionId:localStorage.getItem('chromeId')
                },(res:any) =>{
                  alert(res)
                  setShowLoader(false)
                },(res:any) => {
                  // console.log(res)
                  alert('userName or password is incorrect')
                  setShowLoader(false)
                }
                )

         }
    }    
    const responseGoogle = (response:any) => {
      AuthApi.siginWithGoogle(response)
      // window.location.href = 'https://www.amazon.com/'
      console.log(response);
    }

    const faildResponse = (response:any) => {
      console.log(response)
    }
   return (
    <div onMouseEnter={handleSubmit(onSubmit)} style={{backgroundColor:'#F8F9FC',height:'100vh' ,position:'relative'}}>
      <div style={{backgroundColor:'white',padding:'0px 60px',height:'63px',boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.1)',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <img alt='' src='logo.png' style={{height:'34px'}} />
        <div style={{color:'#7B93AF',fontSize:'16px',fontWeight:400}}>support@Sell-guru.com</div>
      </div>

      <div style={{width:'100%',height:'90%',display:'flex',alignItems:'center',justifyContent:'center'}}>
        {afterLogin ?
          <>
            <div style={{width:'900px',overflow:'hidden',display:'flex',alignItems:'center',justifyContent:'space-between',height:'585px',borderRadius:'11.8508px',boxShadow:'0px 2.96269px 6.66606px rgba(0, 0, 0, 0.3)',backgroundColor:'white'}}>
              <div style={{width:'495px',paddingTop:'44px',paddingLeft:'47px',height:'100%'}}>
                <div style={{color:'#253343',width:'450px',fontSize:'18px',fontWeight:400,letterSpacing:'0.02em',lineHeight:'31px'}}>How would you describe yourself as an AMAZON seller?</div>

                <div>
                  <div onClick={() =>setSelected(1)} style={{width:'380px',cursor:'pointer',boxShadow:selected===1?'0px 0px 4px rgba(0, 0, 0, 0.25)':'unset',marginTop:'14px',backgroundColor:selected===1?'white':'#E6F9F4',borderRadius:'10px',padding:'23px 27px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <div>I am not selling on Amazin (yet!)</div>
                    <div style={{width:'24px',borderRadius:'100%',height:'24px',backgroundColor:'#FFFFFF',border:'1px solid #D0DDEC',display:'flex',alignItems:'center',justifyContent:'center'}}>
                      {
                        selected ===1?
                          <>
                             <div style={{backgroundColor:'#929DFE',width:'16px',height:'16px',borderRadius:'100%'}}></div>
                          </>
                        :undefined
                      }                      
                    </div>
                  </div>

                  <div onClick={() =>setSelected(2)} style={{width:'380px',cursor:'pointer',boxShadow:selected===2?'0px 0px 4px rgba(0, 0, 0, 0.25)':'unset',marginTop:'14px',backgroundColor:selected===2?'white':'#E6F9F4',borderRadius:'10px',padding:'23px 27px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <div style={{width:'306px'}}>I sell on Amazon, but am still learning the basics.</div>
                    <div style={{width:'24px',borderRadius:'100%',height:'24px',backgroundColor:'#FFFFFF',border:'1px solid #D0DDEC',display:'flex',alignItems:'center',justifyContent:'center'}}>
                      {
                        selected ===2?
                          <>
                             <div style={{backgroundColor:'#929DFE',width:'16px',height:'16px',borderRadius:'100%'}}></div>
                          </>
                        :undefined
                      }                      
                    </div>
                  </div>

                  <div onClick={() =>setSelected(3)} style={{width:'380px',cursor:'pointer',boxShadow:selected===3?'0px 0px 4px rgba(0, 0, 0, 0.25)':'unset',marginTop:'14px',backgroundColor:selected===3?'white':'#E6F9F4',borderRadius:'10px',padding:'23px 27px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <div style={{width:'306px'}}>I am an experienced Amazon seller, but could use guidance in more advanced topics.</div>
                    <div style={{width:'24px',borderRadius:'100%',height:'24px',backgroundColor:'#FFFFFF',border:'1px solid #D0DDEC',display:'flex',alignItems:'center',justifyContent:'center'}}>
                      {
                        selected ===3?
                          <>
                             <div style={{backgroundColor:'#929DFE',width:'16px',height:'16px',borderRadius:'100%'}}></div>
                          </>
                        :undefined
                      }                      
                    </div>
                  </div>

                  <div onClick={() =>setSelected(4)} style={{width:'380px',cursor:'pointer',boxShadow:selected===4?'0px 0px 4px rgba(0, 0, 0, 0.25)':'unset',marginTop:'14px',backgroundColor:selected===4?'white':'#E6F9F4',borderRadius:'10px',padding:'23px 27px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <div>I am an Amazon marketplace pro.</div>
                    <div style={{width:'24px',borderRadius:'100%',height:'24px',backgroundColor:'#FFFFFF',border:'1px solid #D0DDEC',display:'flex',alignItems:'center',justifyContent:'center'}}>
                      {
                        selected ===4?
                          <>
                             <div style={{backgroundColor:'#929DFE',width:'16px',height:'16px',borderRadius:'100%'}}></div>
                          </>
                        :undefined
                      }
                    </div>
                  </div>                                                      
                </div>

                <div style={{display:'flex',alignItems:'center',justifyContent:'end',width:'434px'}}>
                  <button onClick={() => {
                     AuthApi.porsesh(selected,token)
                  }} style={{color:'white',cursor:'pointer',fontSize:'14px',fontWeight:400,width:'126px',height:'40px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#929DFE',borderRadius:'8px',border:'none',marginTop:'14px'}}>Run Extension</button>

                </div>
              </div>
              <div style={{position:'relative'}}>
                {/* <div style={{width:'147px',height:'40px',backgroundColor:'#FFFFFF',borderRadius:'5px',border:'1px solid #AEC1DA',position:'absolute',top:'30px',left:'40px',display:'flex',alignItems:'center',justifyContent:'space-evenly'}}>
                  <div onClick={() => setActivePanel('Login')} style={{cursor:'pointer',color:activePanel==='Login'?'white':'#7B93AF',backgroundColor:activePanel==='Login'?'#929DFE':'white',width:'68px',height:'30px',borderRadius:'5px',fontSize:'12px',display:'flex',alignItems:'center',justifyContent:'center'}}>Login</div>
                  <div onClick={() => setActivePanel('Signup')} style={{cursor:'pointer',color:activePanel==='Signup'?'white':'#7B93AF',backgroundColor:activePanel==='Signup'?'#929DFE':'white',width:'68px',height:'30px',borderRadius:'5px',fontSize:'12px',display:'flex',alignItems:'center',justifyContent:'center'}}>Signup</div>
                </div> */}

                {/* <Slide>
                  {slideImages.map((slideImage, index)=> (
                      <div className="each-slide" key={index}>
                        <div style={{'backgroundImage': `url(${slideImage.url})`}}>
                          <span>{slideImage.caption}</span>
                        </div>
                      </div>
                    ))} 
                </Slide>  */}
                <img style={{}} alt='' src='afterLoginBack.png' />
              </div>

            </div>
          </>
        :
        <>
          <div style={{width:'814px',overflow:'hidden',display:'flex',alignItems:'center',justifyContent:'space-between',height:'557px',borderRadius:'11.8508px',boxShadow:'0px 2.96269px 6.66606px rgba(0, 0, 0, 0.3)',backgroundColor:'white'}}>
            <div style={{width:'450px',paddingTop:'28px',height:'100%',overflowY:'scroll'}}>
              <div style={{color:'#253343',fontSize:'40px',fontWeight:500}}>
                {activePanel}
              </div>
              <div style={{fontSize:'14px',marginBottom:'16px',color:'#57728E',fontWeight:400,marginTop:'8px'}}>Join SellGuru to increse your sell on Amazon</div>

              {
                activePanel === 'Signup'?
                  <>
                  <div>
                      <input  onInput={(event:any) =>setregister({...registerdata,name:event.target.value}) } placeholder="Name" {...register("nameField",{ required:'This field is required', maxLength: 30,minLength:3})}   type="text"  style={{width:'322px',height:'40px',color:errors.nameField?'#FC5474':'unset',border:errors.nameField?'1px solid #FC5474':'1px solid #AEC1DA',backgroundColor:'white',paddingLeft:'11px',borderRadius:'8px',marginTop:'13px'}} />
                       <div style={{textAlign:'left'}}>
                        {errors.nameField && <div style={{fontSize:'12px',marginTop:'8px',color:'#FC5474',marginLeft:'30px'}}>{errors.nameField?.message?.toString()}</div>}
                        {errors.nameField?.type==='minLength' && <div style={{fontSize:'12px',marginTop:'8px',color:'#FC5474',marginLeft:'30px'}}>must be at least 3 characters</div>}
                        {errors.nameField?.type==='maxLength' && <div style={{fontSize:'12px',marginTop:'8px',color:'#FC5474',marginLeft:'30px'}}>Must be a maximum of 30 characters</div>}

                       </div>
                  </div>
                  </>
                :undefined
              }

              <div>
                  <input placeholder="Email" onInput={(event:any) =>setregister({...registerdata,email:event.target.value}) } {...register("emailField",{ required:'This field is required', pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g})}  type="Email" style={{width:'322px',height:'40px',color:errors.emailField?'#FC5474':'unset',border:errors.emailField?'1px solid #FC5474':'1px solid #AEC1DA',backgroundColor:'white',paddingLeft:'11px',borderRadius:'8px',marginTop:'13px'}} />
                    <div style={{textAlign:'left'}}>
                      {errors.emailField && <div style={{fontSize:'12px',marginTop:'8px',color:'#FC5474',marginLeft:'30px'}}>{errors.nameField?.message?.toString()}</div>}
                      {errors.emailField?.type==='pattern' && <div style={{fontSize:'12px',marginTop:'8px',color:'#FC5474',marginLeft:'30px'}}>The pattern is not valid</div>}
                    </div>    
                          {/* {errors.emailField?.type=='maxLength' && <div style={{fontSize:'12px',marginTop:'8px',color:'#FC5474'}}>Must be a maximum of 30 characters</div>}                             */}
              </div>   

              <div >
                  <input placeholder="Password" onInput={(event:any) =>setregister({...registerdata,password:event.target.value}) } {...register("passField",{ required:'This field is required',pattern:/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/})}   type="Password" style={{width:'322px',height:'40px',color:errors.passField?'#FC5474':'unset',border:errors.passField?'1px solid #FC5474':'1px solid #AEC1DA',backgroundColor:'white',paddingLeft:'11px',borderRadius:'8px',marginTop:'13px'}} />
                  <div style={{textAlign:'left'}}>
                    {errors.passField && <div style={{fontSize:'12px',width:'322px',marginTop:'8px',color:'#FC5474',marginLeft:'30px'}}>Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters</div>}
                  </div>
              </div>       
              {
                activePanel ==='Login' ? 
                <>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'322px',marginTop:'13px'}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'start'}}>
                            <div style={{marginLeft:'4px'}}>
                                <CheckBox checked={rememberMe} setchecked={() => setRememberMe(!rememberMe)}></CheckBox>
                            </div>
                            <div style={{fontSize:'12px',color:'#445A74',marginLeft:'8px'}}>Remember my login info</div>
                        </div>

                        <div style={{fontSize:'14px',color:'#253343',fontWeight:400,textDecorationLine:'underline'}}>Forget password</div>
                    </div>   
                </>

                :undefined
              }

              <button onClick={activePanel ==='Login' ? () => {
                login()
              } :() => {
                registerUser()
              }} style={{width:'322px',border:'none',backgroundColor:'#929DFE',borderRadius:'8px',color:'white',cursor:'pointer',marginTop:'28px',height:'40px',display:'flex',alignItems:'center',justifyContent:'center'}}>{activePanel} <img style={{marginLeft:'8px'}} alt='' src='fi_log-in.svg' /></button>     

              <div style={{width:'322px',height:'1px',backgroundColor:'#D0DDEC',display:'flex',alignItems:'center',justifyContent:'center',marginTop:'32px',color:'#253343'}}><span style={{backgroundColor:'white',padding:'0px 4px'}}>Or</span> </div>                  
              <GoogleLogin
                  clientId="849222309509-immkjkjh80p2720gah77tpvt6r06h7e9.apps.googleusercontent.com"
                  buttonText="Login"
                  render={(renderProps:any) => (
                   <button onClick={renderProps.onClick} style={{width:'322px',marginBottom:'32px',border:'none',backgroundColor:'#FFA32B',borderRadius:'8px',color:'white',cursor:'pointer',marginTop:'28px',height:'40px',display:'flex',alignItems:'center',justifyContent:'center'}}>Signin with Google <img style={{marginLeft:'8px'}} alt='' src='google-fill.svg' /></button> 
                  )}
                  onSuccess={responseGoogle}
                  onFailure={faildResponse}
                  cookiePolicy={'single_host_origin'}
                />
                {/* <button  onClick={() => {
                  // const params = new Proxy(new URLSearchParams(window.location.search), {
                  //   get: (searchParams, prop) => searchParams.get(prop),
                  // });
                  //   let direction =params.direction
                  //   window.location.href = 'https://sell-guru.com/googlelogin/?chromId='+params.chromeId+'?url='+direction                        
                    }} style={{width:'322px',marginBottom:'32px',border:'none',backgroundColor:'#FFA32B',borderRadius:'8px',color:'white',cursor:'pointer',marginTop:'28px',height:'40px',display:'flex',alignItems:'center',justifyContent:'center'}}>Signin with Google <img style={{marginLeft:'8px'}} alt='' src='google-fill.svg' /></button>      */}
            </div>
            <div style={{position:'relative'}}>
              <div style={{width:'147px',height:'40px',backgroundColor:'#FFFFFF',borderRadius:'5px',border:'1px solid #AEC1DA',position:'absolute',top:'30px',left:'40px',display:'flex',alignItems:'center',justifyContent:'space-evenly'}}>
                <div onClick={() => setActivePanel('Login')} style={{cursor:'pointer',color:activePanel==='Login'?'white':'#7B93AF',backgroundColor:activePanel==='Login'?'#929DFE':'white',width:'68px',height:'30px',borderRadius:'5px',fontSize:'12px',display:'flex',alignItems:'center',justifyContent:'center'}}>Login</div>
                <div onClick={() => setActivePanel('Signup')} style={{cursor:'pointer',color:activePanel==='Signup'?'white':'#7B93AF',backgroundColor:activePanel==='Signup'?'#929DFE':'white',width:'68px',height:'30px',borderRadius:'5px',fontSize:'12px',display:'flex',alignItems:'center',justifyContent:'center'}}>Signup</div>
              </div>
                <img style={{height:'560px'}} alt='' src='back.png' />

            </div>
          </div>
        
        </>  
      }
      </div>

      {
        showLoader?
        <>
          <div style={{position:'absolute',width:'100%',height:'100%',left:'0px',top:'0px',opacity:'0.8',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Loader></Loader>
          </div>
        </>
        :
        undefined
      }

    </div>
  );
}

export default AuthPage