const PlansPanel = () => {

    const resolve7day = () => {
        let date = new Date()
        // date.toLocaleString('default', { month: 'long' })
        date.setDate(date.getDate() + 7)
        let month = date.toLocaleString('default', { month: 'long' })
        let day = date.getUTCDate()
        return (
            <>
                <div style={{color:'#FFFFFF',fontSize:'16px',textAlign:'left',marginTop:'16px'}}>{month +'  '+day}</div>
            </>
        )
        // let mount = date.getUTCMonth()
        // let day = date.getUTCDay()

    }
    return (
        <div style={{width:'100%',height:'630px',backgroundColor:'#253343',display:'flex',alignItems:'center',justifyContent:'space-evenly'}}>
            <div style={{color:'#F5F7FA',textAlign:'left'}}>
                <div style={{fontSize:'32px',textTransform:'uppercase',fontWeight:900}}>Sellsuru Pro</div>
                <div style={{display:'flex',marginTop:'18px',alignItems:'center',justifyContent:'start'}}>
                    <img src="./icons/tik.svg" alt="" />
                    <div style={{fontSize:'16px',fontWeight:400,marginLeft:'14px'}}>Achieve Quantitative Data</div>
                </div>
                <div style={{display:'flex',marginTop:'18px',alignItems:'center',justifyContent:'start'}}>
                    <img src="./icons/tik.svg" alt="" />
                    <div style={{fontSize:'16px',fontWeight:400,marginLeft:'14px'}}>Achieve Qualitative Analysis</div>
                </div>
                <div style={{display:'flex',marginTop:'18px',alignItems:'center',justifyContent:'start'}}>
                    <img src="./icons/tik.svg" alt="" />
                    <div style={{fontSize:'16px',fontWeight:400,marginLeft:'14px'}}>Export Niche Products</div>
                </div>
                <div style={{display:'flex',marginTop:'18px',alignItems:'center',justifyContent:'start'}}>
                    <img src="./icons/tik.svg" alt="" />
                    <div style={{fontSize:'16px',fontWeight:400,marginLeft:'14px'}}>Export Niche Keywords</div>
                </div>
                <div style={{display:'flex',marginTop:'18px',alignItems:'center',justifyContent:'start'}}>
                    <img src="./icons/tik.svg" alt="" />
                    <div style={{fontSize:'16px',fontWeight:400,marginLeft:'14px'}}>Share Niche Products</div>
                </div>
                <div style={{display:'flex',marginTop:'18px',alignItems:'center',justifyContent:'start'}}>
                    <img src="./icons/tik.svg" alt="" />
                    <div style={{fontSize:'16px',fontWeight:400,marginLeft:'14px'}}>Saving Niche</div>
                </div>
                <div style={{display:'flex',marginTop:'18px',alignItems:'center',justifyContent:'start'}}>
                    <img src="./icons/tik.svg" alt="" />
                    <div style={{fontSize:'16px',fontWeight:400,marginLeft:'14px'}}>Comparing Niche Markets</div>
                </div>
                <div style={{display:'flex',marginTop:'18px',alignItems:'center',justifyContent:'start'}}>
                    <img src="./icons/tik.svg" alt="" />
                    <div style={{fontSize:'16px',fontWeight:400,marginLeft:'14px'}}>Using Mentorship</div>
                </div>
                <div style={{display:'flex',marginTop:'18px',alignItems:'center',justifyContent:'start'}}>
                    <img src="./icons/tik.svg" alt="" />
                    <div style={{fontSize:'16px',fontWeight:400,marginLeft:'14px'}}>Create & Download AI Report</div>
                </div>                                                                                                                                
            </div>

            <div>
                <img src="logoWhite.png" alt="" />
                <div style={{color:'#D0DDEC',fontSize:'18px',fontWeight:400,marginTop:'13px'}}>Upgrade Your Tools to be Best</div>

                <div style={{display:'flex',marginTop:'32px',alignItems:'center',justifyContent:'center'}}>
                    <div style={{minWidth:'279px',height:'279px',borderRadius:'20px',background:'white'}}>
                        <div style={{fontSize:'24px',color:'#253343',marginLeft:'28px',fontWeight:500,textAlign:'left',marginTop:'35px'}}>Annual</div>
                        <div style={{display:'flex',padding:'0px 28px',marginTop:'23px',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{color:'#253343',fontSize:'16px',fontWeight:400}}>$18.5 /month</div>
                            <div style={{color:'#7B93AF',fontSize:'14px'}}>x12</div>
                        </div>

                        <div style={{height:'1px',backgroundColor:'#D0DDEC',margin:'12px 17px'}}></div>

                        <div style={{display:'flex',alignItems:'center',margin:'0px 28px',marginRight:'20px',justifyContent:'space-between'}}>
                            <div style={{fontSize:'16px',color:'#253343',fontWeight:400}}>Total</div>
                            <div style={{fontSize:'16px',color:'#253343',fontWeight:400}}>$222 /year</div>
                        </div>

                        <div style={{display:'flex',alignItems:'center',margin:'0px 28px',marginTop:'10px',marginRight:'20px',justifyContent:'space-between'}}>
                            <div style={{fontSize:'16px',color:'#253343',fontWeight:400}}>Payment</div>
                            <div style={{fontSize:'28px',marginRight:'16px',color:'#253343',textAlign:'center',fontWeight:700}}>$0</div>
                        </div>    

                        <button style={{color:'#FFFFFF',border:'none',fontSize:'14px',fontWeight:400,margin:'26px 28px',backgroundColor:'#929DFE',borderRadius:'8px',width:'221px',height:'40px'}}>As soon</button>                    
                    </div>

                    <div style={{minWidth:'279px',marginLeft:'26px',height:'279px',borderRadius:'20px',background:'white'}}>
                        <div style={{fontSize:'24px',color:'#253343',marginLeft:'28px',fontWeight:500,textAlign:'left',marginTop:'35px'}}>Monthly</div>
                        <div style={{display:'flex',padding:'0px 28px',marginTop:'44px',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{color:'#253343',fontSize:'16px',fontWeight:400}}>Builed today at</div>
                            {/* <div style={{color:'#7B93AF',fontSize:'14px'}}>x12</div> */}
                        </div>
                        
                        <div style={{color:'#253343',textAlign:'left',marginLeft:'28px',fontSize:'27px',fontWeight:700,marginTop:'11px'}}>
                            $20.4 <span style={{fontWeight:400,fontSize:'16px'}}>/month</span>
                        </div>
                        {/* <div style={{height:'1px',backgroundColor:'#D0DDEC',margin:'12px 17px'}}></div>

                        <div style={{display:'flex',alignItems:'center',margin:'0px 28px',marginRight:'20px',justifyContent:'space-between'}}>
                            <div style={{fontSize:'16px',color:'#253343',fontWeight:400}}>Total</div>
                            <div style={{fontSize:'16px',color:'#253343',fontWeight:400}}>$222 /year</div>
                        </div>

                        <div style={{display:'flex',alignItems:'center',margin:'0px 28px',marginTop:'10px',marginRight:'20px',justifyContent:'space-between'}}>
                            <div style={{fontSize:'16px',color:'#253343',fontWeight:400}}>Payment</div>
                            <div style={{fontSize:'28px',marginRight:'16px',color:'#253343',textAlign:'center',fontWeight:700}}>$0</div>
                        </div>     */}

                        <button style={{color:'#253343',border:'none',fontSize:'14px',fontWeight:400,margin:'0px 28px',marginTop:'50px',backgroundColor:'#E9EDF5',borderRadius:'8px',width:'221px',height:'40px'}}>Purchase</button>                    
                    </div>
                </div>

                <div style={{color:'#D0DDEC',fontSize:'18px',fontWeight:400,marginTop:'36px',textAlign:'left'}}>How the annual free trial works</div>
                
                <div style={{position:'relative',marginTop:'30px'}}>
                    <div style={{width:'15px',height:'15px',backgroundColor:'#FFA32B',borderRadius:'100%',border:'3px solid #253343',position:'absolute',top:'-9px',left:'-5px',zIndex:10}}></div>
                    <div style={{width:'15px',height:'15px',backgroundColor:'#9DA7FE',borderRadius:'100%',border:'3px solid #253343',position:'absolute',top:'-9px',left:'230px',zIndex:10}}></div>

                    <div style={{width:'580px',height:'2px',backgroundImage:'linear-gradient(to right, #D6EEFF , #FFFFFF00)',opacity:'0.4'}}></div>

                    <div style={{display:'flex',alignItems:'center',justifyContent:'start'}}>
                        <div style={{width:'230px'}}>
                            <div style={{color:'#FFFFFF',fontSize:'16px',textAlign:'left',marginTop:'16px'}}>Today</div>
                            <div style={{color:'#92A7C1',marginTop:'8px',fontSize:'13px',fontWeight:'400px',width:'177px',textAlign:'left'}}>Start studying with Sellguru Pro. Free for 7 days.</div>
                        </div>
                        <div>
                            {resolve7day()}
                            <div style={{color:'#92A7C1',marginTop:'8px',fontSize:'13px',fontWeight:'400px',width:'307px',textAlign:'left'}}>Free trial ends. You are billed for a one-year subscription unless you’ve canceled during free trial.</div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlansPanel