import React from 'react';
import { Archive, DownloadAndInstall,Compare, HeaderBar, LookingForProducts, QualitiveAnalize, SaveDiffrntNiche, SellingOnAmazon, WhatsPepoleSayes, FooterBar } from './components';
import './App.css';
// import Log from './model/Log';
// import LogApi from './Api/LogApi';
import {
  HashRouter as Router,
  Route,
  Switch} from 'react-router-dom'
import {AuthPage, Payment } from './Pages';
import AuthPage2 from './Pages/AuthPage2';

function App() {
  // const newLog = () => {
  //   let log = new Log('openLandingPage',navigator.appCodeName +'/'+navigator.appName+'/'+navigator.appVersion +'/'+navigator.platform +'/'+navigator.userAgent,'',navigator.userAgent)
  //   LogApi.create(log)
  // }
  return (
    <div className="App" style={{overflowX:'hidden'}}>
      <Router>
          {/* <Switch> */}
          <Switch>
            <Route path="/authentication">
                <AuthPage2></AuthPage2>
            </Route>     

            <Route path="/payment">
                <Payment></Payment>
            </Route>       
            <Route path="/">
              <HeaderBar></HeaderBar>
              <QualitiveAnalize></QualitiveAnalize>
              <DownloadAndInstall></DownloadAndInstall>
              <LookingForProducts></LookingForProducts>
              <Archive></Archive>
              <SaveDiffrntNiche></SaveDiffrntNiche>
              <Compare></Compare>
              <SellingOnAmazon></SellingOnAmazon>
              <img style={{marginTop:'146px',width:'60%'}} src="./imgs/Table.png" alt="" />
              <WhatsPepoleSayes></WhatsPepoleSayes>
              <FooterBar></FooterBar>
              {/* <Switch></Switch> */}
            </Route>



          </Switch>
          {/* </Switch>    */}
      </Router>      
    </div>
  );
}

export default App;
