const SaveDiffrntNiche = () => {
    return (
        <>
            <div style={{position:'relative',marginTop:'150px'}}>
                <div style={{display:window.innerWidth>600 ?'flex':'block',textAlign:'left',justifyContent:'center'}}>
                    <img style={{marginRight:'0px',height:'191px'}} src="./imgs/4.gif" alt="" />
                    <div>
                        <div className="headerGreen">Save Different Niches</div>
                        <p className="pargraphcontent" style={{width:'304px'}}>Manage and Compare all Niches You Find in Your Product Research </p>
                        <div style={{display:'flex',justifyContent:'end'}}>
                            <button onClick={() => window.open('https://chrome.google.com/webstore/detail/sellguru-free-amazon-nich/kpbjfajjiamcmkngfffakflnfkincbmb')} className="ButtonBlue" style={{marginTop:'30px',padding:'9px 30px'}}><span>Try it</span> </button>
                        </div>
                    </div>
                </div>
                <img className="responsiveOrderGridLayout" style={{position:'relative',width:window.innerWidth>600?'60%':'100%',zIndex:100}} src="./imgs/saveNiche.png" alt="" />
                <img style={{width:'100%',position:'absolute',top:'388px',left:'0px'}} src="./imgs/back1.png" alt="" />
                {/* <img className="mobileInvisible" style={{display:window.innerWidth>600 ?'block':'none',position:'absolute',top:'-200px',left:'0px'}} src="./imgs/back3.png" alt="" />
                <img className="mobileInvisible" style={{display:window.innerWidth>600 ?'block':'none',position:'absolute',top:'150px',right:'0px'}} src="./imgs/SellGuru is different.png" alt="" /> */}
            </div>        
        </>
    )
}

export default SaveDiffrntNiche