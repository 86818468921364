import React from "react"
import Message from "./Message"

const WhatsPepoleSayes = () => {
   document.getElementById('Messages4')?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
    
    const Messages = [
        {   
            content:"It seems eventually I can coach my students easily and I can help them in finding a profitable product to sell on Amazon. An useful extension which helps both mentors and sellers to succeed"
        },
    ]
    return (
        <>
            <div style={{marginTop:'200px',marginBottom:'154px'}}>
                <div className="responsiveMarginMentorsSay" style={{display:'flex',alignItems:'center'}}>
                    <div style={{width:'26px',height:'26px',backgroundColor:'#FFB800',borderRadius:'100%'}}></div>
                    <h2 style={{color:'#253343',fontSize:'35px',fontWeight:300,marginLeft:window.innerWidth>1024?'20px':'8px'}}>What Mentors says</h2>
                </div>
                <div style={{marginTop:'50px',display:'flex',alignItems:'center',justifyContent:'center',overflowX:'scroll'}} className="hiidenScrollBar">
                    {Messages.map((item,index) => {
                        return (
                            <>
                                <Message  item={item} index={index}></Message>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default WhatsPepoleSayes