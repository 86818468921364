const MasterFooter = () => {
    return (
        <>
        <div style={{position:'relative',width:'100%',backgroundColor:'rgba(37, 51, 67, 0.9)'}}>
           
            <div style={{display:'flex',position:'relative',alignItems:'center',justifyContent:'start'}}>
                <div style={{width:'450px',marginLeft:'100px',textAlign:'left'}}>
                    <img style={{width:'157px'}} src="./logoWhite.png" alt="" />
                    <div style={{fontSize:'12px',textAlign:'left',marginTop:'8px',fontWeight:400,color:'#F5F7FA'}}>Platform for Sales in Amazon</div>

                    <input type="email" style={{width:'208px',height:'40px',borderRadius:'5px',backgroundColor:'white',marginTop:'16px',padding:'0px 16px'}} placeholder="Subscribe to our newsletter" />
                    <button style={{width:'244px',height:'40px',border:'none',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#929DFE',marginTop:'8px',borderRadius:'8px',color:'white'}}>Subscribe</button>
                </div>


                <div style={{display:'flex',width:'100%',alignItems:'start',marginTop:'36px',marginBottom:'30px',justifyContent:'space-evenly',textAlign:'left'}}>
                    <div style={{color:'#FFFFFF',fontSize:'14px',fontWeight:400}}>
                        <div style={{fontSize:'18px',fontWeight:500}}>Platform</div>
                        <div style={{marginTop:'18px'}}>What is Board?</div>
                        <div style={{marginTop:'12px'}}>Comminuty</div>
                        <div style={{marginTop:'12px'}}>Mentorship programe</div>
                        <div style={{marginTop:'12px'}}>Events</div>
                        <div style={{marginTop:'12px'}}>Extentaion</div>
                    </div>
                    <div style={{color:'#FFFFFF',fontSize:'14px',fontWeight:400}}>
                        <div style={{fontSize:'18px',fontWeight:500}}>Services</div>
                        <div style={{marginTop:'18px'}}>Niche sresearch</div>
                        <div style={{marginTop:'12px'}}>Compatitive advantage</div>
                        <div style={{marginTop:'12px'}}>Sourcing</div>
                        <div style={{marginTop:'12px'}}>Lunching Plan</div>
                    </div>
                    <div style={{color:'#FFFFFF',fontSize:'14px',fontWeight:400}}>
                        <div style={{fontSize:'18px',fontWeight:500}}>Mentor</div>
                        <div style={{marginTop:'18px'}}>Mentor list</div>
                        <div style={{marginTop:'12px'}}>Become mentor</div>
                    </div>
                    <div style={{color:'#FFFFFF',fontSize:'14px',fontWeight:400}}>
                        <div style={{fontSize:'18px',fontWeight:500}}>Company</div>
                        <div style={{marginTop:'18px'}}>About us</div>
                        <div style={{marginTop:'12px'}}>Contact us</div>
                        <div style={{marginTop:'12px'}}>FAQ</div>
                        <div style={{marginTop:'12px'}}>Pricing</div>
                    </div>                                                            
                </div>

            </div>
             <img  style={{width:'100%',position:'absolute',top:'0px',left:'0px',zIndex:-10,height:'-webkit-fill-available'}} src="./imgs/backFooter2.png" alt="" />

        </div>
        </>
    )
}

export default MasterFooter